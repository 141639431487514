<template>
  <div>
    <NavbarStick  @toggleMenu="openDrawer"/>
    <router-view @toggleMenu="openDrawer"/>
    <FooterComp />
    <!-- DRAWER MENU -->
    <DrawerMenu :showDrawer="showDrawer" @closeDrawer="closeDrawer" />
  </div>
</template>

<script>
import NavbarStick from '@/components/general/NavbarStick.vue';
import FooterComp from '@/components/general/FooterComp.vue';
import DrawerMenu from '@/components/general/DrawerMenu.vue'
export default {
  name: "DefaultLayout",
  components: {
    FooterComp,
    NavbarStick,
    DrawerMenu
  },
  data() {
    return {
      showDrawer: false
    }
  },
  methods: {
    closeDrawer() {
      this.showDrawer = false
    },
    openDrawer() {
      this.showDrawer = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>